import {
  Button,
  Container,
  Text,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
  Box,
} from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";

import { FiDownload } from "react-icons/fi";

export const Route = createFileRoute("/_layout/results")({
  component: Results,
});

function ResultsTable() {
  const data = [
    {
      id: 1,
      status: "COMPLETE",
      item_id: "1",
      rate: "36%",
    },
    {
      id: 2,
      status: "COMPLETE",
      item_id: "2",
      rate: "85%",
    },
    {
      id: 3,
      status: "COMPLETE",
      item_id: "3",
      rate: "54%",
    },
    {
      id: 4,
      status: "COMPLETE",
      item_id: "4",
      rate: "67%",
    },
  ];

  return (
    <>
      <TableContainer>
        <Table size={{ base: "sm", md: "md" }}>
          <Thead>
            <Tr>
              <Th>ID</Th>
              <Th>Status</Th>
              <Th>Job ID</Th>
              <Th>Overall Evasion Rate</Th>
              <Th>Export Files</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data.map((result: any) => (
              <Tr key={result.id} opacity={false ? 0.5 : 1}>
                <Td>{result.id}</Td>
                <Td>{result.status}</Td>
                <Td>{result.item_id}</Td>
                <Td color={!result.rate ? "ui.dim" : "inherit"}>
                  {result.rate || "N/A"}
                </Td>
                <Td>
                  <Icon as={FiDownload} alignSelf="center" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex
        gap={4}
        alignItems="center"
        mt={4}
        direction="row"
        justifyContent="flex-end"
      >
        <Button>Previous</Button>
        <span>Page 1</span>
        <Button>Next</Button>
      </Flex>
    </>
  );
}

function Results() {
  return (
    <Container maxW="full">
      <Heading size="lg" textAlign={{ base: "center", md: "left" }} pt={12}>
        Evasion Results
      </Heading>
      <Text>
        Lookup metrics across for jobs that have been run on the Evasive
        platform and download evasive samples for retraining your detectors.
      </Text>

      <Box mt={5}>
      <ResultsTable />
      </Box>
    </Container>
  );
}
