import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { type SubmitHandler, useForm } from "react-hook-form";

import {
  type ApiError,
  type JobPublic,
  type JobUpdate,
  JobsService,
} from "../../client";
import useCustomToast from "../../hooks/useCustomToast";

interface EditJobProps {
  job: JobPublic;
  isOpen: boolean;
  onClose: () => void;
}

const EditJob = ({ job, isOpen, onClose }: EditJobProps) => {
  const queryClient = useQueryClient();
  const showToast = useCustomToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = useForm<JobUpdate>({
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues: job,
  });

  const mutation = useMutation({
    mutationFn: (data: JobUpdate) =>
      JobsService.updateJob({ id: job.id, requestBody: data }),
    onSuccess: () => {
      showToast("Success!", "Job updated successfully.", "success");
      onClose();
    },
    onError: (err: ApiError) => {
      const errDetail = (err.body as any)?.detail;
      showToast("Something went wrong.", `${errDetail}`, "error");
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["Jobs"] });
    },
  });

  const onSubmit: SubmitHandler<JobUpdate> = async (data) => {
    mutation.mutate(data);
  };

  const onCancel = () => {
    reset();
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={{ base: "sm", md: "md" }}
        isCentered
      >
        <ModalOverlay />
        <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>Edit Job</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4}>
              <FormLabel htmlFor="parameters">Parameters</FormLabel>
              <Input
                id="parameters"
                {...register("status")}
                placeholder="Parameters"
                type="text"
              />
            </FormControl>
          </ModalBody>
          <ModalFooter gap={3}>
            <Button
              variant="primary"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!isDirty}
            >
              Save
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EditJob;
