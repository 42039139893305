import { Box, Flex, Icon, Text, useColorModeValue } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import {
  FiDatabase,
  FiPlayCircle,
  FiMonitor,
  FiHome,
  FiSettings,
  FiUsers,
} from "react-icons/fi";

import type { UserPublic } from "../../client";

const items = [
  { icon: FiHome, title: "Dashboard", path: "/" },
  { icon: FiDatabase, title: "File Library", path: "/items" },
  { icon: FiPlayCircle, title: "Perturbation Jobs", path: "/jobs" },
  { icon: FiMonitor, title: "Results", path: "/results" },
];

const bottomItems = [
  { icon: FiSettings, title: "User Settings", path: "/settings" },
];

interface SidebarItemsProps {
  onClose?: () => void;
}

const SidebarItems = ({ onClose }: SidebarItemsProps) => {
  const queryClient = useQueryClient();
  const textColor = useColorModeValue("ui.main", "ui.light");
  const bgActive = useColorModeValue("#E2E8F0", "#4A5568");
  const currentUser = queryClient.getQueryData<UserPublic>(["currentUser"]);

  const finalBottomItems = currentUser?.is_superuser
    ? [
        ...bottomItems,
        {
          icon: FiUsers,
          title: "Org Settings",
          path: "/admin",
        },
      ]
    : bottomItems;

  const topListItems = items.map(({ icon, title, path }) => (
    <Flex
      as={Link}
      to={path}
      w="100%"
      p={2}
      key={title}
      activeProps={{
        style: {
          background: bgActive,
          borderRadius: "12px",
        },
      }}
      color={textColor}
      onClick={onClose}
    >
      <Icon as={icon} alignSelf="center" />
      <Text ml={2}>{title}</Text>
    </Flex>
  ));

  const bottomListItems = finalBottomItems.map(({ icon, title, path }) => (
    <Flex
      as={Link}
      to={path}
      w="100%"
      p={2}
      key={title}
      activeProps={{
        style: {
          background: bgActive,
          borderRadius: "12px",
        },
      }}
      color={textColor}
      onClick={onClose}
    >
      <Icon as={icon} alignSelf="center" />
      <Text ml={2}>{title}</Text>
    </Flex>
  ));

  return (
    <>
      <Box>{topListItems}</Box>
      <Box mt="400px">{bottomListItems}</Box>
    </>
  );
};

export default SidebarItems;
