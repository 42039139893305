import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { createFileRoute } from "@tanstack/react-router";

export const Route = createFileRoute("/_layout/")({
  component: Dashboard,
});

function Dashboard() {
  return (
    <>
      <Container maxW="full" maxH="full">
        <Heading size="lg" textAlign={{ base: "center", md: "left" }} pt={12}>
          Evasion Metrics Across All Jobs
        </Heading>
        <Text>
          Cumulative evasion metrics across all jobs that have been run on the
          Evasive platform.
        </Text>
        <Box pt={2} m={4}>
          <iframe
            src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/237555883600/dashboards/bcf9e91c-42df-4bbd-87e9-8c5395816ce9?directory_alias=quicksight-evasive-ai"
            width="960px"
            height="810px"
          />
        </Box>
      </Container>
    </>
  );
}
